@import './config/variables';

form {
  input {
    outline: none;

    &:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 40px $white--f inset;
    }
  }
  .input {
    display: block;
    width: 100%;
    padding: 8px 15px;
    background: $grey--e;
    border: none;

    &-like {
      @extend .input;
      overflow: hidden;

      input {
        float: left;
        width: 80%;
        padding: 0;
        border: none;
        background: $grey--e;
      }
    }
  }
}
