@import './u';
@import './global';
@import './title';
@import './form';
@import './button';
@import './reseter';

* {
  box-sizing: border-box;
}

body, html, .theme {
  height: 100%;
}

/**
 * Disable zoom on input focus on safari
 * https://stackoverflow.com/questions/2989263/disable-auto-zoom-in-input-text-tag-safari-on-iphone
 */
@media screen and (-webkit-min-device-pixel-ratio:0) {
  select:focus,
  textarea:focus,
  input:focus {
    font-size: 16px !important;
  }
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  select,
  textarea,
  input {
    font-size: 16px !important;
  }
}
