@import './config/variables';

.button {
  width: 100%;
  padding: 8px 15px;
  text-align: center;
  // text-transform: uppercase;
  font-weight: 700;
  border: none;

  &-primary {
    @extend .button;
    background: #9E3642;
    color: $white--f;
  }

  &-disabled {
    @extend .button;
    background: $grey--e;
    color: $grey--8;
  }
}
