hr {
  border: none;
  border-top: 1px solid $black--0;
}

a {
  text-transform: none;
  text-decoration: none;
  color: inherit;
}

button {
  color: inherit;
  -webkit-appearance: none;
  border-radius: 0;
  border: none;
  background-color: inherit;
}

input[type=checkbox],
input[type=checkbox]:focus {
  -webkit-appearance: none;
  outline:0;
  -moz-appearance: none;
}


div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	// vertical-align: baseline;
}

body {
	line-height: 1;
}

ol, ul {
	list-style: none;
}

blockquote, q {
	quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}
