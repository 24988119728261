$grey--e: #eee;
$grey--3: #333;
$grey--8: #888;
$black--0: #000;
$white--f: #fff;
$red: #d32f2f;
$green: #43a047;
$green---pmt: #9dc212;

$fontSizeTitle: 1.29em;

$fontWeightMedium: 500;
