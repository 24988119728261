* {
  -webkit-tap-highlight-color: transparent;
}

body {
  margin: 0;
  padding: 0;
}

hr.separation {
  width: 90%;
  margin: 0 auto;
  border: none;
  border-top-width: 2px;
  border-top-style: solid;
}
