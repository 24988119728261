@import './config/variables';

h1,
h2,
h3,
h4,
h5 {
  margin: 0;
  color: $grey--3;
}

.h1-like {
  font-size: 24px;
  font-weight: 500;
  text-transform: uppercase;
}
.h2-like {
  font-size: 21px;
  font-weight: 500;
  text-transform: uppercase;
  opacity: 0.4;
}
.h3-like {
  font-size: 17px;
  font-weight: 500;
}
.h4-like {
  font-size: 17px;
  font-weight: 400;
  opacity: 0.4;
}
.h5-like {
  font-size: 15px;
  font-weight: 500;
}
